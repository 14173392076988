export const UPDATE_GRID = 'grid:updateGrid';

export function updateGrid(rows){
    return {
        type:UPDATE_GRID,
        payload:{
            rows: rows,
        }
    }
}
