import React from "react";
import { CircularProgress, Stack, Typography} from "@mui/material";

export default function MySpinner(){
    return (
        <Stack direction={"column"} alignItems={"center"} justifyItems={"center"} sx={{ m:5 }}>
            <CircularProgress />
            <Typography>Harap Tunggu...</Typography>
        </Stack>
    );
}