import { DataHelpers } from "../../helpers";
import { UPDATE_FORM } from "../actions/formActions";

const initialState = DataHelpers.FORMS.begin;

export default function formReducers(state=initialState, {type,payload}) {
	switch(type)
	{
		case UPDATE_FORM:
			let nstate = {...state,...payload.rows};
			state = nstate;
			return state;
		default:
			return state;
	}
}
