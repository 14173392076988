import { CssBaseline } from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { CachesServices, UserServices } from './helpers';
import Dashboard from './pages/Dashboard';
import Login from './pages/Authentication/Login';
import { connect } from 'react-redux';
import { updateUser } from './store/actions';
import Register from './pages/Authentication/Register';
import Simulasi from './pages/Simulasi';
import _ from 'lodash';

function App(props) 
{
	let navigate = useNavigate();
	const location = useLocation();

	useEffect(()=>{
		async function Todo(){
			// if(!_.includes(['/login','/register','/simulasi'],location.pathname)){
			// 	if(!UserServices.IsLogin(props)) return navigate('/login');
			// 	await CachesServices.init();
			// }
		}
		Todo();
		// eslint-disable-next-line
	},[]);

    return (
        <div className="App">
			<CssBaseline />
			<Routes>
				{/* <Route path="/*" element={<Dashboard /> } />
				<Route path="/login" element={<Login /> } />
				<Route path="/register" element={<Register /> } /> */}
				<Route path="/" element={<Simulasi /> } />
			</Routes>
        </div>
      );    
}

const mapStateToProps = state => ({
    user:state.user,
});

const mapActionsToProps = {
    onUpdateUser: updateUser,
};

export default connect(mapStateToProps,mapActionsToProps)(App)