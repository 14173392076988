import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Box, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import { Checkbox, Select, Textfield } from '../../components/form';
import { useRef } from 'react';
import { connect } from 'react-redux';
import { DataHelpers } from '../../helpers';
import _ from 'lodash';
import { updateCalc, updateForm, updateTask, updateUser } from '../../store/actions';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import MyButtonAction from '../../components/common/MyButtonAction';

function Index(props) {

    const FormRef = useRef(null);
    const DATA = DataHelpers;
    
    function Rules(){
        let eumur = "Inputan dengan angka minimal 18 tahun dan maksimal 65 tahun";
        let epensiun = "Inputan dengan angka minimal 55 tahun dan maksimal 65 tahun";
        let edana = "Inputan dengan angka minimal 100.000";
        let eiuran = "Inputan dengan angka minimal 100.000/bulan";
        let eiuran_tahunan = "Inputan dengan angka minimal 1.200.000/tahun";
        let edana_iuran = "Masukan Iuran Rutin dengan angka";
        return Yup.object().shape({
            agree: Yup.string().required('Wajib di Centang'),
            metode: Yup.string().required('Pilih metode pembayaran cicilan'),
            umur: Yup.number().required(eumur).positive(eumur).integer(eumur).min(18,eumur).max(65,eumur).typeError(eumur).label("Error"),
            umur_pensiun: Yup.number().required(epensiun).positive(epensiun).integer(epensiun).min(55,epensiun).max(65,epensiun).typeError(epensiun).label("Error"),
            dana_awal: Yup.number().required(edana).integer(edana).min(100000,edana).typeError(edana).label("error"),
            dana_iuran: Yup.number().required(edana_iuran).integer(edana_iuran).when("metode",{ 
                is: "1",
                then: (schema) => schema.min(100000,eiuran).typeError(eiuran),
                otherwise: (schema) => schema.min(1200000,eiuran_tahunan).typeError(eiuran_tahunan)
            }).label("error"),
        });
    };
    
    const onHandleChange = evt => {
        const { name,value } = evt.target;
        FormRef.current.setFieldValue(name, value);
        FormRef.current.setFieldTouched("dana_iuran");
        props.onUpdateCalc({metode:value});
    };

    async function HandleHitung()
    {
        props.onUpdateForm(DATA.FORMS.processing);

        let errors = await FormRef.current.validateForm();
        if(!_.isEmpty(errors)){
            // props.onUpdateForm({...DataHelpers.FORMS.begin,"errmessage":errors,"errors":true,"values":FormRef.current.values});
            props.onUpdateForm(DATA.FORMS.begin);
            _.map(errors, (v,k)=>{ FormRef.current.setFieldTouched(k); });
        }
        else{
            
            props.onUpdateForm(DATA.FORMS.begin);
            let D6,E6,D7,E7,D8,E8,D10,E10,D15,D16,D17,E15,E16,E17,C11,C12,D13,E13;
            D6=E6=D7=E7=D8=E8=D10=E10=D15=D16=D17=E15=E16=E17=C11=C12=D13=E13=0;
            const { umur,umur_pensiun,dana_awal,dana_iuran,agree,metode } = FormRef.current.values;
            
            D6 = parseInt(umur_pensiun) - parseInt(umur);
            E6 = D6*12;
            D7 = E7 = parseInt(dana_awal);
            if(metode==="1"){
                E8 = parseInt(dana_iuran);
                D8 = E8*12;
            }
            else if(metode==="2"){
                D8 = parseInt(dana_iuran);
                E8 = D8/12;
            }

            // D10 = parseInt(biaya_adm_bulan)*12;
            // E10 = parseInt(biaya_adm_bulan);
            E10 = 1000;
            D10 = E10*12;

            // C11 = parseFloat(persen_biaya_kelola)/100;
            // C12 = parseFloat(persen_tingkat_bunga)/100;
            C11 = 1.25/100;
            C12 = 6/100;
            D13 = C12-C11;
            E13 = D13/12;

            D15 = (D8*D6)+dana_awal;
            D16 = (D8-D10)*((((1+D13)**D6)-1)/D13)+(D7*((1+D13)**D6));
            D17 = D16-D15;
            
            let D15_ = (D15).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            let D16_ = (D16).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            let D17_ = (D17).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            props.onUpdateCalc({yakumulasi_iuran:D15_});
            props.onUpdateCalc({ynilai_manfaat:D16_});
            props.onUpdateCalc({yhasil_pengembangan:D17_});
            
            E15 = (E8*E6)+dana_awal;
            E16 = (E8-E10)*((((1+E13)**E6)-1)/E13)+(E7*((1+E13)**E6));
            E17 = E16-E15;
            let E15_ = (E15).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            let E16_ = (E16).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            let E17_ = (E17).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            props.onUpdateCalc({makumulasi_iuran:E15_});
            props.onUpdateCalc({mnilai_manfaat:E16_});
            props.onUpdateCalc({mhasil_pengembangan:E17_});
        }
    }

    return (
    <Box sx={{ backgroundImage:`url(${process.env.PUBLIC_URL+'/bg-7.jpg'})`}}>
        <Container maxWidth="md">
            <Header {...props} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={1} sx={{ p:2,mb:5 }}>
                        <Formik
                            validationSchema={Rules()}
                            innerRef={FormRef}
                            initialValues={{
                                "umur":30,
                                "umur_pensiun":55,
                                "dana_awal":200000,
                                "dana_iuran":200000,
                                "metode":"1"
                            }}
                        >
                            {(formProps)=>(
                                <Form>
                                    <Stack spacing={3}>
                                        <Textfield name="umur" label="Usia Saat ini" helperText="Usia minimum pembukaan rekening DPLK Bumiputera adalah 18 tahun" />
                                        <Textfield name="umur_pensiun" label="Usia Pensiun" helperText="Usia Pensiun minimum 55 Tahun" />
                                        <Textfield name="dana_awal" label="Dana Awal" helperText="Setoran Awal minimum Rp. 100.000" />
                                        {/* {props.calc.metode==="1"? */}
                                        <Textfield name="dana_iuran" label="Iuran Rutin" helperText={props.calc.metode==="1"?"Setoran Awal minimum Rp. 100.000":"Setoran Awal minimum Rp. 1.200.000"} />
                                        {/* :
                                        <Textfield name="dana_iuran_tahunan" label="Iuran Tetap Tahunan" helperText="Setoran Awal minimum Rp. 1.200.000" />
                                        } */}
                                        <Select name="metode" options={{"1": "Bulanan", "2": "Tahunan"}} helperText="Cara Pembayaran Iuran" onChange={onHandleChange} />
                                        <Stack>
                                        <Typography variant="caption">Catatan</Typography>
                                        <Typography variant="caption">*  Iuran sebenarnya dapat dilakukan kapanpun</Typography>
                                        <Typography variant="caption">** Seluruh biaya dapat berubah sewaktu-waktu</Typography>
                                        </Stack>
                                        <Checkbox name="agree" label={"Saya memahami bahwa hasil simulasi ini merupakan estimasi hasil akhir sebenarnya dapat berbeda"} />
                                        {formProps.errors.agree?
                                        <Typography variant="caption" color={"red"}>Anda Wajib centang</Typography>
                                        :
                                        ''
                                        }
                                        <MyButtonAction {...props} label="Hitung" labeling="Hitung..." onHandleAction={HandleHitung} />
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    {props.calc.metode==="1"?(
                    <Paper sx={{ p:2, mb:2 }}>
                        <Paper square={false} sx={{ p:1,mb:1, backgroundColor:"#f0f0f0" }}>Hasil Simulasi(Bulanan)</Paper>
                        <Grid container spacing={1}>
                            <Grid item md={8}><Typography variant="subtitle">AKUMULASI IURAN</Typography></Grid><Grid item md={4}><Typography>{props.calc.makumulasi_iuran}</Typography></Grid>
                            <Grid item md={8}><Typography variant="subtitle">NILAI MANFAAT PENSIUN</Typography></Grid><Grid item md={4}><Typography>{props.calc.mnilai_manfaat}</Typography></Grid>
                            <Grid item md={8}><Typography variant="subtitle">HASIL PENGEMBANGAN</Typography></Grid><Grid item md={4}><Typography>{props.calc.mhasil_pengembangan}</Typography></Grid>
                        </Grid>
                    </Paper>
                    ):(
                    <Paper sx={{ p:2, mb:2 }}>
                        <Paper square={false} sx={{ p:1,mb:1, backgroundColor:"#f0f0f0" }}>Hasil Simulasi(Tahunan)</Paper>
                        <Grid container spacing={1}>
                            <Grid item md={8}><Typography variant="subtitle">AKUMULASI IURAN</Typography></Grid><Grid item md={4}><Typography>{props.calc.yakumulasi_iuran}</Typography></Grid>
                            <Grid item md={8}><Typography variant="subtitle">NILAI MANFAAT PENSIUN</Typography></Grid><Grid item md={4}><Typography>{props.calc.ynilai_manfaat}</Typography></Grid>
                            <Grid item md={8}><Typography variant="subtitle">HASIL PENGEMBANGAN</Typography></Grid><Grid item md={4}><Typography>{props.calc.yhasil_pengembangan}</Typography></Grid>
                        </Grid>
                    </Paper>
                    )}
                    <Paper sx={{ p:2, mb:5 }}>
                        <Grid container spacing={1}>
                            <Grid item md={8}><Typography variant="subtitle">Biaya Pengelolaan Per Tahun</Typography></Grid><Grid item md={4}><Typography>1.25%</Typography></Grid>
                            <Grid item md={8}><Typography variant="subtitle">Tingkat Bunga</Typography></Grid><Grid item md={4}><Typography>6.0%</Typography></Grid>
                            <Grid item md={8}><Typography variant="subtitle">Biaya Admin {props.calc.metode==="1"?"per bulan":"per tahun"}</Typography></Grid><Grid item md={4}><Typography>{props.calc.metode==="1"?"1.000":"12.000"}</Typography></Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        <Footer />
    </Box>
  );
}

const mapStateToProps = state => ({
    user:state.user,
    task:state.task,
    form:state.form,
    calc:state.calc,
});

const mapActionsToProps = {
    onUpdateUser: updateUser,
    onUpdateTask: updateTask,
    onUpdateForm: updateForm,
    onUpdateCalc: updateCalc,
};

export default connect(mapStateToProps,mapActionsToProps)(Index)