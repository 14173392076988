import { Box, Paper, Typography } from "@mui/material";

export default function Footer(){

    return <>
    <Paper sx={{
        marginTop: 'calc(10% + 60px)',
        position: 'fixed',
        zIndex:999,
        bottom: 0,
        width: '100%',
        backgroundImage:`url(${process.env.PUBLIC_URL+'/bg-1.jpg'})`,
        }} component="footer" square variant="outlined">
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            mt: 1,
            mb: 1,
          }}
        >
        <Typography variant="caption" color="white">Copyright ©2024 DPLK Bumiputera</Typography>
        </Box>
    </Paper>
    </>
}
