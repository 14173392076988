import { Box, Button, Divider, Paper, Stack, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import themes from "../../components/ui/themes";

function Index(props) {
    let navigate = useNavigate();

    return (
    <Paper elevation={1} sx={{ p:2, mb:2 }}>
        <Stack spacing={2}>
            <Box>
                <Typography variant='button'>Nama</Typography>
                <Divider />
                <Typography variant='body2'>{props.user.name}</Typography>
            </Box>
            <Box>
                <Typography variant='button'>Email</Typography>
                <Divider />
                <Typography variant='body2'>{props.user.email}</Typography>
            </Box>
            <Box>
                <Typography variant='button'>HP/WhatsApp</Typography>
                <Divider />
                <Typography variant='body2'>{props.user.hp}</Typography>
            </Box>
            <Box>
                <Button variant="contained" sx={{ ...themes.buttonBlue }} onClick={()=>{return navigate("/change-profil");}}>Ubah Data Profil</Button>
            </Box>
        </Stack>
    </Paper>

  	);
}

const mapStateToProps = state => ({
    form:state.form,
    user:state.user,
  });

export default connect(mapStateToProps,null)(Index)
// export default Index;
