import { createTheme, responsiveFontSizes} from '@mui/material';
import { amber, blueGrey, green, grey, lightBlue, orange } from '@mui/material/colors';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

export default responsiveFontSizes(createTheme({
    typography:{
        fontFamily:"Raleway",
        // h4:{
        //     fontSize: '1.2rem',
        //     '@media (max-width:599px)': {fontSize: '1.5rem',},
        //     '@media (min-width:600px)': {fontSize: '2.0rem',},
        //     '@media (min-width:900px)': {fontSize: '2.5rem',},
        // }
    },
    palette: {
        primary: blueGrey,
        secondary: amber,
        anger: createColor('#F40B27'),
        apple: createColor('#5DBA40'),
        steelBlue: createColor('#5C76B7'),
        violet: createColor('#BC00A3'),
    },
    buttonBlue:{
        textTransform:"none",
        borderRadius:20,
        paddingLeft:'30px',
        paddingRight:'30px',
        backgroundColor:lightBlue[300],
        color:palette.getContrastText(lightBlue[300]),
        '&:hover':{backgroundColor:lightBlue[500],}
    },
    button:{
        textTransform:"none",
        borderRadius:20,
        paddingLeft:'30px',
        paddingRight:'30px',
    },
    buttonGrey:{
        textTransform:"none",
        borderRadius:20,
        paddingLeft:'30px',
        paddingRight:'30px',
        backgroundColor:grey[300],
        color:palette.getContrastText(grey[300]),
        '&:hover':{backgroundColor:grey[500],}
    },
    buttonOrange:{
        textTransform:"none",
        borderRadius:20,
        paddingLeft:'30px',
        paddingRight:'30px',
        backgroundColor:orange[300],
        color:palette.getContrastText(orange[300]),
        '&:hover':{backgroundColor:orange[500],}
    },
    circularProgress:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
        color:palette.getContrastText(grey[300]),
    },
    buttonChip:{
        cursor:'pointer',
        '&:hover':{backgroundColor:blueGrey[200],}
    },
    tableRowHeader: {
        backgroundColor:blueGrey[100],
        color:palette.getContrastText(blueGrey[200]),
        '&:last-child td, &:last-child th': { border: 0 }
    },
}));