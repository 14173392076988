import { Box, Container, Grid } from '@mui/material';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Header from '../../components/common/Header';
import { updateTask, updateUser } from '../../store/actions';
import Footer from '../../components/common/Footer';
import Profil from './Profil';
import UbahPassword from './UbahPassword';
import UbahProfil from './UbahProfil';
import MySpinner from '../../components/common/MySpinner';
import Menu from '../../components/common/Menu';
import { RoutepointServices } from '../../helpers';


function Index(props) {
	const POINT = RoutepointServices;

    return (
    <>
    {/* { props.form.success && (<><Typography variant='body2' color="blue">{props.form.message}</Typography><Divider sx={{ mb:2 }} /></>)} */}
    <Box sx={{ backgroundImage:`url(${process.env.PUBLIC_URL+'/bg-7.jpg'})`, display:"flex"}}>
      <Container maxWidth="md">
      <Header />
      <Grid container spacing={2}>
        <Grid item xs={5} sm={4} md={3}>
			<Menu {...props} />
        </Grid>
        <Grid item xs={7} sm={8} md={9} sx={{ mb:20 }}>
			{(props.task.init===POINT.LOGOUT.init)?
			(<MySpinner />)
			:
			(
          	<Routes>
            <Route path="*" element={<Profil /> } />
            <Route path="/change-password" element={<UbahPassword /> } />
            <Route path="/change-profil" element={<UbahProfil /> } />
          	</Routes>
			)
			}
        </Grid>
      </Grid>
      </Container>
	  <Footer />
    </Box>
    </>
  	);
}

const mapStateToProps = state => ({
	task:state.task,
    user:state.user,
  });

  const mapActionsToProps = {
	  onUpdateTask: updateTask,
	  onUpdateUser: updateUser,
  };

export default connect(mapStateToProps,mapActionsToProps)(Index)
