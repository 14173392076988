import _ from "lodash";
import { DataHelpers } from "./DataHelpers";
import { CachesServices } from "./CachesServices";

export const UserServices = {
    IsLogin,
    Logout,
};

function IsLogin(props){
    if(_.isUndefined(localStorage.getItem('user'))) return null;
    else if(_.isUndefined(localStorage.getItem('token'))) return null;

    let token = localStorage.getItem('token');
    let user = JSON.parse(localStorage.getItem('user'));

    if(token && user){
        if(props) props.onUpdateUser(user);
        return true;
    }else return null;
}

function Logout(props){
    localStorage.clear();
    CachesServices.removeAll();
    // localStorage.removeItem('token');
    // localStorage.removeItem('user');
    props.onUpdateUser(DataHelpers.USER.begin);
    props.onUpdateTask(DataHelpers.TASK.begin);
}