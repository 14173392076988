import { DataHelpers } from "../../helpers";
import { UPDATE_GRID } from "../actions/gridActions";

const initialState = DataHelpers.GRIDS.begin;

export default function gridReducers(state=initialState, {type,payload}) {
	switch(type)
	{
		case UPDATE_GRID:
			let nstate = {...state,...payload.rows};
			state = nstate;
			return state;
		default:
			return state;
	}
}
