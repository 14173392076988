import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import themes from "../ui/themes";


// function Header(props){
export default function Header(){
    let navigate = useNavigate();
    
    return <>
    <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} alignContent={"center"}>
            <Paper elevation={2} sx={{ p:5, mt:1, backgroundImage:`url(${process.env.PUBLIC_URL+'/bg-1.jpg'})` }}>
                <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={4}>
                    <img src={process.env.PUBLIC_URL+'/logo350.png'} alt="logo" width={"100%"} onClick={()=>{return navigate("/dashboard");}} />
                </Grid>
                <Grid item xs={6} sm={6} md={8} alignContent={"center"}>
                    <Typography variant="h4" color={"white"}>DPLK BUMIPUTERA</Typography>
                    <Typography variant="subtitle1" color={"white"}>System Aplikasi Online</Typography>
                </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ p:1, justifyContent:"center", backgroundImage:`url(${process.env.PUBLIC_URL+'/bg-7.jpg'})` }}>
            <Stack direction="row" spacing={1}>
                <Button size="small" variant="contained" sx={{ ...themes.button }} onClick={()=>{return navigate("/");}}>Home</Button>
            </Stack>
        </Grid>
    </Grid>
    </>
}