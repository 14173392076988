import { Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AddCard, LogoutOutlined, Person } from "@mui/icons-material";
import { DataHelpers, HttpServices, RoutepointServices, UserServices } from "../../helpers";

export default function Menu(props){
    let navigate = useNavigate();
	const POINT = RoutepointServices;
	const DATA = DataHelpers;
	const HTTP = HttpServices;

    const Logout = async () => {
		props.onUpdateTask(DATA.TASK.logout);
		await HTTP.get(POINT.LOGOUT.api,function(res){
            UserServices.Logout(props);
			return navigate(POINT.LOGIN.route);
		});
    };


    return <>
    <Paper elevation={1} sx={{ p:1, mb:2}}>
        <MenuList>
            <MenuItem onClick={()=>{return navigate("/");}}>
            <ListItemIcon><Person fontSize="small" /></ListItemIcon>
            <ListItemText>User Profile</ListItemText>
            </MenuItem>

            <MenuItem onClick={()=>{return navigate("/change-password");}}>
            <ListItemIcon><Person fontSize="small" /></ListItemIcon>
            <ListItemText>Ubah Password</ListItemText>
            </MenuItem>

            <MenuItem onClick={()=>{return navigate("/saldo");}}>
            <ListItemIcon><AddCard fontSize="small" /></ListItemIcon>
            <ListItemText>Saldo</ListItemText>
            </MenuItem>

            <Divider />
            <MenuItem onClick={Logout}>
            <ListItemIcon><LogoutOutlined fontSize="small" /></ListItemIcon>
            <ListItemText>Logout</ListItemText>
            </MenuItem>
        </MenuList>
    </Paper>
    </>
}