import axios from "axios";
import _ from "lodash";
import { RoutepointServices } from "./RoutepointHelpers";

const apiDomain = RoutepointServices.API;

export const HttpServices = {
	get,
	post,
	put,
	remove,
	Headers,
};

async function get(url,callback) {
	let headers = Headers();
	try {
		let response = await axios.get(apiDomain + url, headers);
		if(_.isFunction(callback)) callback(response);
		return response;
	} catch (error) { 
		if(_.isFunction(callback)) callback(error.response);
		return error.response;
	}
}

async function post(url, data, multipart, callback){
	let headers = Headers();
	let formdata = data;
	if (multipart) {
		headers.headers["Content-Type"] = "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2);
		console.log('headers :>> ', headers);
		formdata = new FormData();
		Object.keys(data).map((key) => {
			formdata.append(key, data[key]);
			return 1;
		});
	}

	try {
		let response = await axios.post(apiDomain + url, formdata, headers);
		if(_.isFunction(callback)) callback(response);
		return response;
	} catch (error) { 
		if(_.isFunction(callback)) callback(error.response);
		return error.response;
	}
}

async function put(url, data, multipart, callback){
	let headers = Headers();
	let formdata = data;
	if (multipart) {
		headers.headers["Content-Type"] = "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2);
		console.log('headers :>> ', headers);
		formdata = new FormData();
		Object.keys(data).map((key) => {
			formdata.append(key, data[key]);
			return 1;
		});
	}

	try {
		let response = await axios.put(apiDomain + url, formdata, headers);
		if(_.isFunction(callback)) callback(response);
		return response;
	} catch (error) { 
		if(_.isFunction(callback)) callback(error.response);
		return error.response;
	}
}

async function remove(url,data,callback) {
	let headers = Headers();
	headers.data = data;
	try {
		let response = await axios.delete(apiDomain + url, headers);
		if(_.isFunction(callback)) callback(response);
		return response;
	} catch (error) { 
		if(_.isFunction(callback)) callback(error.response);
		return error.response;
	}
}

function Headers() {
	const token = localStorage.getItem("token");
	return {
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: "Bearer " + (token ? token : null),
		},
	};
}
