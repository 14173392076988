export const UPDATE_FORM = 'form:updateForm';

export function updateForm(rows){
    return {
        type:UPDATE_FORM,
        payload:{
            rows: rows,
        }
    }
}
