import * as Yup from 'yup';
import { Form, Formik} from 'formik';
import { Avatar, Box, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Textfield } from '../../components/form';
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import { updateForm, updateTask, updateUser } from '../../store/actions';
import { useNavigate } from 'react-router-dom';
import { HttpServices, UserServices, DataHelpers, RoutepointServices } from '../../helpers';
import MyButtonAction from '../../components/common/MyButtonAction';


function Index(props) {
    let navigate = useNavigate();
    const FormRef = useRef(null);
    const DATA = DataHelpers;
    const POINT = RoutepointServices;

    useEffect(() => {
		function Todo() {
            UserServices.Logout(props);
            props.onUpdateForm(DATA.FORMS.begin);
		}
		Todo();
		// eslint-disable-next-line
    }, [])

    function Rules(){
        return Yup.object().shape({
            email: Yup.string().required('Wajib Isi').email("Must be a Email Format"),
            password: Yup.string().required('Wajib Isi'),
        });
    };

    async function HandleLogin()
    {
        props.onUpdateForm(DATA.FORMS.processing);

        let errors = await FormRef.current.validateForm();
        if(!_.isEmpty(errors)){
            props.onUpdateForm({...DATA.FORMS.begin,"values":FormRef.current.values});
            _.map(errors, (v,k)=>{FormRef.current.setFieldTouched(k);});
        }
        else{
            await HttpServices.post(POINT.LOGIN.api,FormRef.current.values,null,function(row){
            console.log('row :>> ', row);
                if(row.status && row.status===200)
                {
                    localStorage.setItem('token', row.data.data.token);
                    localStorage.setItem('user', JSON.stringify(row.data.data.user));
                    props.onUpdateUser(row.data.data.user);
                    return navigate('/');
                }else{
                    props.onUpdateForm(DATA.FORMS.failed);
                }
            });
        }
    }

    return (
    <Box sx={{ backgroundImage:`url(${process.env.PUBLIC_URL+'/bg-7.jpg'})`}}>
        <Container maxWidth="md">
            <Header {...props} />
            <Grid container spacing={2}>
                <Grid item sm={4} md={4} sx={{ display:{xs:"none",sm:"block",md:"block",} }}>
                    <Paper elevation={2} sx={{ p:5, mb:2, backgroundImage:`url(${process.env.PUBLIC_URL+'/bg-1.jpg'})` }}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Avatar sx={{ bgcolor: 'lightblue' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography variant='button' color={"white"}>Signin</Typography>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                    <Paper elevation={1} sx={{ p:2,mb:10 }}>
                        {/* { props.form.success && (<><Typography variant='body2' color="blue">{props.form.message}</Typography><Divider sx={{ mb:2 }} /></>)} */}
                        <Formik
                            validationSchema={Rules()}
                            innerRef={FormRef}
                            initialValues={{"email":"admin@gmail.com","password":"123456"}}
                            >
                            {(formProps)=>(
                                <Form>
                                    <Stack spacing={1}>
                                        <Textfield name="email" label="Email" autoComplete="email" />
                                        <Textfield name="password" label="Password" />
                                        <Divider />
                                        {props.form.errors && (
                                            <Typography color="red">Email dan Password tidak cocok. Coba ulangi lagi.</Typography>
                                        )}
                                        <MyButtonAction {...props} label="Login" labeling="Logging..." onHandleAction={HandleLogin} />                                        
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        <Footer />
    </Box>
  );
}

const mapStateToProps = state => ({
    user:state.user,
    task:state.task,
    form:state.form,
});

const mapActionsToProps = {
    onUpdateUser: updateUser,
    onUpdateTask: updateTask,
    onUpdateForm: updateForm,
};

export default connect(mapStateToProps,mapActionsToProps)(Index)
// export default Index;
