import { RoutepointServices } from "./RoutepointHelpers";

const POINT = RoutepointServices;

const GRIDS =
{
    begin    : {title:null,subtitle:null,datafields:null,columns:null,data:null,datarow:null,init:null},
};

const FORMS =
{
    begin    :  {processing:null,success:null,errors:null,param:{title:null,subtitle:null,rules:null,defaultvalues:{}}},
    processing: {processing:true,success:null,errors:null},
    success  :  {processing:null,success:true,errors:null},
    failed   :  {processing:null,success:null,errors:true},
};

const TASK =
{
    begin    : {init:null,action:null},
    logout   : {init:POINT.LOGOUT.init},
    ajuan   : {
        begin : {init:POINT.TAJUAN.init,action:null},
        end : {init:POINT.TAJUAN.init,action:"end"},
    },
};

const USER = {
    begin    : {name:null,email:null,hp:null},
};

const CALC = {
    begin    : {
        yakumulasi_iuran:0,
        ynilai_manfaat:0,
        yhasil_pengembangan:0,
        makumulasi_iuran:0,
        mnilai_manfaat:0,
        mhasil_pengembangan:0,
        metode:"1",
    },
};

export const DataHelpers =
{
    FORMS,
    USER,
    TASK,
    CALC,
    GRIDS,
};


