export const UPDATE_CALC = 'calc:updateCalc';

export function updateCalc(calc){
    return {
        type:UPDATE_CALC,
        payload:{
            calc: calc,
        }
    }
}
