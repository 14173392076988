import * as Yup from 'yup';
import { Form, Formik} from 'formik';
import { Avatar, Box, Button, CircularProgress, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { Textfield } from '../../components/form';
import themes from "../../components/ui/themes";
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import { AppRegistration } from '@mui/icons-material';
import { HttpServices, DataHelpers } from '../../helpers';
import Alerterror from '../../components/common/Alerterror';
import { updateForm,updateTask, updateUser } from '../../store/actions';


function Index(props) {

    const FormRef = useRef(null);
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
    // min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
    let navigate = useNavigate();

    useEffect(() => {
		function fetchBusinesses() {
			props.onUpdateForm(DataHelpers.Forms.begin);
		}
		fetchBusinesses();
		// eslint-disable-next-line
	}, [])

    function Rules(){
        return Yup.object().shape({
            name: Yup.string().required('Wajib Isi').min(3),
            email: Yup.string().required('Wajib Isi').email("Must be a Email Format"),
            hp: Yup.number().required('Wajib Isi').positive().integer().min(8).typeError("Must be a Number Format"),
            // password: Yup.string().required('Wajib Isi'),
            password: Yup.string().required('Wajib Isi').min(6).matches(passwordRules, { message: "password should contain at least 1 lower case letter, 1 upper case letter, 1 digit and 1 symbol" }),
            repassword: Yup.string().required('Wajib Isi').oneOf([Yup.ref('password'), null], 'Passwords must match')
        });
    };

    async function HandleDaftar()
    {
        props.onUpdateForm({...DataHelpers.Forms.processing});

        let errors = await FormRef.current.validateForm();
        if(!_.isEmpty(errors)){
            props.onUpdateForm({...DataHelpers.Forms.begin,"values":FormRef.current.values});
            _.map(errors, (v,k)=>{FormRef.current.setFieldTouched(k);});
        }
        else{
            await HttpServices.post('/users/signup',FormRef.current.values,null,function(row){
            console.log('row :>> ', row);
                if(row.status===200)
                {
                    props.onUpdateForm({...DataHelpers.Forms.success,message:"Data sudah tersimpan. Silahkan login"});
					// console.log('props.history :>> ', props.history);
                    return navigate('/login');
                }else{
					props.onUpdateForm({...DataHelpers.Forms.failed,errmessage:row.data.error});
                }
            });
        }
    }

    return (
    <Box sx={{ backgroundImage:`url(${process.env.PUBLIC_URL+'/bg-7.jpg'})`}}>
        <Container maxWidth="md">
            <Header {...props} />
            <Grid container spacing={2}>
                <Grid item sm={4} md={4} sx={{ display:{xs:"none",sm:"block",md:"block",} }}>
                    <Paper elevation={2} sx={{ p:5, mb:2, backgroundImage:`url(${process.env.PUBLIC_URL+'/bg-1.jpg'})` }}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Avatar sx={{ bgcolor: 'lightblue' }}>
                                <AppRegistration />
                            </Avatar>
                            <Typography variant='button' color={"white"}>Register</Typography>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                    <Paper elevation={1} sx={{ p:2,mb:10 }}>
                        <Formik
                            validationSchema={Rules()}
                            innerRef={FormRef}
                            initialValues={{}}
                            >
                            {(formProps)=>(
                                <Form>
                                    <Stack spacing={1}>
                                        <Textfield name="name" label="Nama Lengkap"/>
                                        <Textfield name="email" label="Email" />
                                        <Textfield name="hp" label="Nomor HP/WhatsApp" />
                                        <Textfield name="password" label="Password" />
                                        <Textfield name="repassword" label="Re-Password" />
                                        <Divider />
                                        {props.form.errors && (<Alerterror {...props} />)}
                                        <Stack direction="row" justifyContent={"space-between"}>
                                            <Box orientation="vertical" sx={{ display: 'flex', position: 'relative'}}>
                                                <Box sx={{ position: 'relative' }}>
                                                {props.form.processing?(
                                                    <>
                                                        <Button variant="contained" disabled sx={{ ...themes.buttonBlue }}>Proses...</Button>
                                                        <CircularProgress size={24} sx={{ ...themes.circularProgress }}/>
                                                    </>
                                                ):(
                                                    <Button variant="contained" sx={{ ...themes.buttonBlue }} onClick={HandleDaftar} >Daftar</Button>
                                                )}
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        <Footer />
    </Box>
  );
}

const mapStateToProps = state => ({
    form:state.form,
    user:state.user,
    task:state.task,
  });

  const mapActionsToProps = {
    onUpdateForm: updateForm,
    onUpdateUser: updateUser,
    onUpdateTask: updateTask,
  };

export default connect(mapStateToProps,mapActionsToProps)(Index)
// export default Index;
