import React from "react";
import { Box, Button, CircularProgress, Stack, Typography} from "@mui/material";
import themes from "../../components/ui/themes";


export default function MyButtonAction(props){
    return (
        <Stack direction="row" justifyContent={"space-between"}>
        <Box orientation="vertical" sx={{ display: 'flex', position: 'relative'}}>
            <Box sx={{ position: 'relative' }}>
            {props.form.processing?(
                <>
                <Button size="small" variant="contained" disabled sx={{ ...themes.buttonBlue }}>{props.labeling}</Button>
                <CircularProgress size={24} sx={{ ...themes.circularProgress }}/>
                </>
            ):(
                <Button size="small" variant="contained" sx={{ ...themes.buttonBlue }} onClick={props.onHandleAction} >{props.label}</Button>
            )}
            </Box>
        </Box>
    </Stack>
    );
}