import { DataHelpers } from "../../helpers";
import { UPDATE_USER } from "../actions/userActions";

const initialState = DataHelpers.USER.begin;

export default function userReducers(state=initialState, {type,payload}) {
	switch(type)
	{
		case UPDATE_USER:
			let nstate = {...state,...payload.user};
			state = nstate;
			return state;
		default:
			return state;
	}
}
