import * as Yup from 'yup';
import { Form, Formik} from 'formik';
import { Box, Button, CircularProgress, Divider, Paper, Stack } from '@mui/material';
import { Textfield } from '../../components/form';
import themes from "../../components/ui/themes";
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { DataHelpers } from '../../helpers';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { HttpServices } from '../../helpers/HttpServices';
import Alerterror from '../../components/common/Alerterror';
import { updateForm, updateUser } from '../../store/actions';


function Index(props) {

    const FormRef = useRef(null);
    let navigate = useNavigate();
    const DATA = DataHelpers;
    // const {user, setUser} = useState(null);

	useEffect(()=>{
		function Todo(){
			props.onUpdateForm(DATA.FORMS.begin);
		}
		Todo();
        // eslint-disable-next-line
	},[]);

    function Rules(){
        return Yup.object().shape({
            name: Yup.string().required('Wajib Isi').min(3),
            email: Yup.string().required('Wajib Isi').email("Must be a Email Format"),
            hp: Yup.number().required('Wajib Isi').positive().integer().min(8).typeError("Must be a Number Format"),
        });
    };

    async function HandleUbah()
    {
        props.onUpdateForm({...DataHelpers.Forms.processing});

        let errors = await FormRef.current.validateForm();
        if(!_.isEmpty(errors)){
            props.onUpdateForm({...DataHelpers.Forms.begin,"values":FormRef.current.values});
            _.map(errors, (v,k)=>{FormRef.current.setFieldTouched(k);});
        }
        else{
            let row = await HttpServices.post('/profil/edit',FormRef.current.values,null); //,function(row){
                console.log('row :>> ', row);
                if(row.status===200)
                {
                    localStorage.setItem('user', JSON.stringify(FormRef.current.values));
                    props.onUpdateUser(FormRef.current.values);
                    props.onUpdateForm({...DataHelpers.Forms.success,message:"Data tersimpan"});
                    return navigate('/');
                }else{
					props.onUpdateForm({...DataHelpers.Forms.failed,errmessage:row.data.error});
                }
            // });
        }
    }

    return (
        <Paper elevation={1} sx={{ p:2, mb:10 }}>
        <Formik
            validationSchema={Rules()}
            innerRef={FormRef}
            initialValues={_.pick(JSON.parse(localStorage.getItem('user')),["name","email","hp"])}
            >
            {(formProps)=>(
                <Form>
                    <Stack spacing={1}>
                        <Textfield name="name" label="Nama Lengkap"/>
                        <Textfield name="email" label="Email" />
                        <Textfield name="hp" label="Nomor HP/WhatsApp" />
                        <Divider />
                        {props.form.errors && (<Alerterror {...props} />)}
                        <Stack direction="row" justifyContent={"space-between"}>
                            <Box orientation="vertical" sx={{ display: 'flex', position: 'relative'}}>
                                <Box sx={{ position: 'relative' }}>
                                {props.form.processing?(
                                    <>
                                        <Button variant="contained" disabled sx={{ ...themes.buttonBlue }}>Proses...</Button>
                                        <CircularProgress size={24} sx={{ ...themes.circularProgress }}/>
                                    </>
                                ):(
                                    <Button variant="contained" sx={{ ...themes.buttonBlue }} onClick={HandleUbah} >Ubah</Button>
                                )}
                                </Box>
                            </Box>
                            <Button variant="contained" sx={{ ...themes.buttonGrey }} onClick={()=>{return navigate("/");}}>Batal</Button>
                        </Stack>
                    </Stack>
                </Form>
            )}
        </Formik>
        </Paper>
  );
}

const mapStateToProps = state => ({
    form:state.form,
    user:state.user,
  });

  const mapActionsToProps = {
    onUpdateForm: updateForm,
    onUpdateUser: updateUser,
  };

export default connect(mapStateToProps,mapActionsToProps)(Index)
// export default Index;
