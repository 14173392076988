import { Alert, List, ListItem, ListItemText } from "@mui/material";
import _ from "lodash";

export default function Alerterror(props){
    return (<Alert severity="warning">
        {_.map(props.form.errmessage,(v,k)=>{ 
            return (
            <List key={k} dense={true} sx={{ p:0 }}><ListItem><ListItemText secondary={v} /></ListItem></List>
            )
            }
        )}
    </Alert>)
}
