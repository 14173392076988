import { legacy_createStore as createStore } from "redux";
// import { thunk } from "redux-thunk";
import allReducer from './reducers'
import { DataHelpers } from "../helpers";

// const allStoreEnhancers = compose(
//     applyMiddleware(thunk),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );
// console.log('DataHelpers.Forms :>> ', DataHelpers.Forms);
export default createStore(allReducer,{
    form:DataHelpers.FORMS.begin,
    task:DataHelpers.TASK.begin,
    calc:DataHelpers.CALC.begin,
    user:DataHelpers.USER.begin,
    grid:DataHelpers.GRIDS.begin,
});