import { combineReducers } from "redux";
import formReducers from "./formReducers";
import taskReducers from "./taskReducers";
import calcReducers from "./calcReducers";
import userReducers from "./userReducers";
import gridReducers from "./gridReducers";

export default combineReducers({ 
    form:formReducers,
    grid:gridReducers,
    task:taskReducers,
    calc:calcReducers,
    user:userReducers,
})