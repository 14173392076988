import { HttpServices } from "./HttpServices";
import { RoutepointServices } from "./RoutepointHelpers";

const HTTP  = HttpServices;
const POINT  = RoutepointServices;
const CACHES = caches.open(POINT.CACHENAME);


export const CachesServices = {
    init,
	create,
	update,
	getOrCreate,
	removeItem,
	removeAll,
};

async function init() {
	return await CACHES.then((cache) => {
		console.log(`Cache ${POINT.CACHENAME} opened`);
	});
};

async function getOrCreate(urlString){
	// const {urlString} = param;
	const url = POINT.API+urlString;
	return await caches.match(url).then(async (response) => {
		if(response) return await get(urlString);
		else return await create(urlString);
	});
}

async function get(urlString){
	
	const url = POINT.API+urlString;
	return await caches.match(url).then( async (response)=> {
		return await response.clone().json();
	}).then(async (data)=>{ 
		return data;
	});
}

async function update(urlString){
	await removeItem(urlString);
	return await create(urlString,HTTP.Headers());
};

async function create(urlString){
	const url = POINT.API+urlString;
	return await CACHES.then(async (cache) => {
		await cache.add(new Request(url, HTTP.Headers()));
	})
	.then(async ()=>{ 
		return await get(urlString); 
	});
}

async function removeItem(urlString) {
	const url = POINT.API+urlString;
	return await CACHES.then(async (cache) =>{ 
		await cache.delete(url);
	});
};

function removeAll() {
	caches.delete(POINT.CACHENAME).then((isGone) => {
		console.log("remove cache",isGone);
	});
};
