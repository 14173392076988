import { DataHelpers } from "../../helpers";
import { UPDATE_CALC } from "../actions/calcActions";

const initialState = DataHelpers.CALC.begin;

export default function calcReducers(state=initialState, {type,payload}) {
	switch(type)
	{
		case UPDATE_CALC:
			let nstate = {...state,...payload.calc};
			state = nstate;
			return state;
		default:
			return state;
	}
}
