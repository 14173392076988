import * as Yup from 'yup';
import { Box, Button, CircularProgress, Divider, Paper, Stack } from '@mui/material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import themes from "../../components/ui/themes";
import { updateForm } from '../../store/actions';
import { Form, Formik } from 'formik';
import { Textfield } from '../../components/form';
import Alerterror from '../../components/common/Alerterror';
import { DataHelpers } from '../../helpers';
import { useEffect, useRef } from 'react';
import { HttpServices } from '../../helpers/HttpServices';
import _ from 'lodash';


function Index(props) {
    let navigate = useNavigate();
	const FormRef = useRef(null);
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
	const DATA = DataHelpers;
    // min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

	useEffect(()=>{
		function Todo(){
			props.onUpdateForm(DATA.FORMS.begin);
		}
		Todo();
		// eslint-disable-next-line
	},[]);

    function Rules(){
        return Yup.object().shape({
            oldpassword: Yup.string().required('Wajib Isi'),
            password: Yup.string().required('Wajib Isi').min(6).matches(passwordRules, { message: "Input Password minimal 6 karakter(minimal 1 huruf besar dan kecil)" }),
            repassword: Yup.string().required('Wajib Isi').oneOf([Yup.ref('password'), null], 'Passwords must match')
        });
    };

    async function HandleUbah()
    {
        props.onUpdateForm({...DataHelpers.Forms.processing});

        let errors = await FormRef.current.validateForm();
        if(!_.isEmpty(errors)){
            props.onUpdateForm({...DataHelpers.Forms.begin,"values":FormRef.current.values});
            _.map(errors, (v,k)=>{FormRef.current.setFieldTouched(k);});
        }
        else{
            await HttpServices.post('/profil/change-password',FormRef.current.values,null,function(row){
            console.log('row :>> ', row);
                if(row.status===200)
                {
                    props.onUpdateForm({...DataHelpers.Forms.success,message:"Data tersimpan"});
                    return navigate('/');
                }else{
					props.onUpdateForm({...DataHelpers.Forms.failed,errmessage:row.data.error});
                }
            });
        }
    }

    return (
		<Paper elevation={1} sx={{ p:2, mb:10 }}>
		<Formik
			validationSchema={Rules()}
			innerRef={FormRef}
			initialValues={{}}
			>
			{(formProps)=>(
				<Form>
					<Stack spacing={1}>
						<Textfield name="oldpassword" label="Password Lama" />
						<Textfield name="password" label="Password Baru" />
						<Textfield name="repassword" label="Re-Password" />
						<Divider />
						{props.form.errors && (<Alerterror {...props} />)}
						<Stack direction="row" justifyContent={"space-between"}>
							<Box orientation="vertical" sx={{ display: 'flex', position: 'relative'}}>
								<Box sx={{ position: 'relative' }}>
								{props.form.processing?(
									<>
										<Button variant="contained" disabled sx={{ ...themes.buttonBlue }}>Proses...</Button>
										<CircularProgress size={24} sx={{ ...themes.circularProgress }}/>
									</>
								):(
									<Button variant="contained" sx={{ ...themes.buttonBlue }} onClick={HandleUbah} >Ubah</Button>
								)}
								</Box>
							</Box>
							<Button variant="contained" sx={{ ...themes.buttonGrey }} onClick={()=>{return navigate("/");}}>Batal</Button>
						</Stack>
					</Stack>
				</Form>
			)}
		</Formik>
		</Paper>
  	);
}

const mapStateToProps = state => ({
    form:state.form,
  });

  const mapActionsToProps = {
    onUpdateForm: updateForm,
  };

export default connect(mapStateToProps,mapActionsToProps)(Index)
// export default Index;
