export const UPDATE_TASK = 'task:updateTask';

export function updateTask(task){
    return {
        type:UPDATE_TASK,
        payload:{
            task: task,
        }
    }
}
