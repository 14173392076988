import { DataHelpers } from "../../helpers";
import { UPDATE_TASK } from "../actions/taskActions";

const initialState = DataHelpers.TASK.begin;

export default function taskReducers(state=initialState, {type,payload}) {
	switch(type)
	{
		case UPDATE_TASK:
			let nstate = {...state,...payload.task};
			state = nstate;
			return state;
		default:
			return state;
	}
}
